<template>
    <a-modal v-model="thisVisible" :width="680" :bodyStyle="{'padding':'0 30px 30px 30px'}" :footer="null" @cancel="onClose()" centered>
        <template slot="title">
            <a-menu mode="horizontal" :selectedKeys="[activeMenuIndex]" @click="onMenuClick">
                <a-menu-item key="0"> 学豆明细 </a-menu-item>
                <a-menu-item key="1"> 兑换记录 </a-menu-item>
                <a-menu-item key="2"> 学豆规则 </a-menu-item>
                <a-menu-item key="info" disabled>
                    <span class="huiwen-text-small">当前学豆：</span> <span class=" huiwen-text-black">{{sum_score}}</span><span class="huiwen-text-small">，昨日共获得</span> <span class=" huiwen-text-black">{{score_today}}</span> <span class="huiwen-text-small">学豆</span>
                </a-menu-item>
            </a-menu>
        </template>
        <div class="huiwen-container">
            <div v-if="activeMenuIndex == '0'" class="huiwen-container">
                <a-list item-layout="horizontal" :data-source="add_logs">
                    <a-list-item slot="renderItem" slot-scope="item, index">
                        <a-list-item-meta :description="item.update_time">
                            <span slot="title">{{ item.comments }}</span>
                        </a-list-item-meta>
                        <div>+ {{ item.score_change }}</div>
                    </a-list-item>
                </a-list>
                <cmy-page-old :page_now="add_logs_pagination.page" :bo_end="add_logs_pagination.end" @next="queryAddLogs(add_logs_pagination.page + 1)" @previous="queryAddLogs(add_logs_pagination.page - 1)"></cmy-page-old>
            </div>
            <div v-if="activeMenuIndex == '1'" class="huiwen-container">
                <a-list item-layout="horizontal" :data-source="exchange_logs">
                    <a-list-item slot="renderItem" slot-scope="item, index">
                        <a-list-item-meta :description="item.update_time">
                            <span slot="title">{{ item.comments }}</span>
                        </a-list-item-meta>
                        <div>- {{ item.score_change }}</div>
                    </a-list-item>
                </a-list>
            </div>
            <div v-if="activeMenuIndex == '2'" class="huiwen-container" v-html="score_rules"></div>
        </div>
    </a-modal>
</template>

<script>
    import { doQuery, doAction } from "@/api/core";
    import CmyPageOld from '@/components/cmy-page/cmy-page-old.vue'
    export default {
        name: "my-integration",
        props: {
            visible: Boolean
        },
        components: {
          CmyPageOld
        },
        watch:{
            'visible':function(newVal,oldVal) {
                if(newVal && !oldVal) {
                    this.getIntegrationRule()
                    this.queryAddLogs()
                        .then(() => {
                            this.thisVisible = true
                        })

                }
                if(!newVal && oldVal) {
                    this.thisVisible = false
                }
            }
        },
        data() {
            return {
                thisVisible: false,

                activeMenuIndex: '0',

                sum_score: 0,
                score_today: 0,
                score_rules: '',

                exchange_logs: [],
                add_logs: [],

                exchange_logs_pagination: {
                    page: 1,
                    limit: 5,
                    end: true
                },

                add_logs_pagination: {
                    page: 1,
                    limit: 5,
                    end: true
                }
            }
        },
        methods: {
            onClose() {
                const that = this
                that.$emit('close')
            },
            getIntegrationRule() {
                const that = this
                doQuery({
                    query_code: 'LNSC40',
                    page: 1,
                    limit: 1
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data) {
                        that.sum_score = res.data[0].sum_score || 0
                        that.score_rules = res.data[0].score_rules
                        that.score_today = res.data[0].score_today || 0
                    }
                })
            },

            onMenuClick({ key }) {
                const that = this
                switch (key) {
                    case '0':
                        that.queryAddLogs()
                            .then(() => {
                                that.activeMenuIndex = key
                            })
                        break
                    case '1':
                        that.queryExchangeLogs()
                            .then(() => {
                                that.activeMenuIndex = key
                            })
                        break
                    case '2':
                        that.activeMenuIndex = key
                        break
                }

            },

            queryAddLogs(page = 1) {
                const that = this
                return new Promise((resolve,reject) => {
                    doQuery({
                        query_code: 'LNSC20',
                        page: page,
                        limit: that.add_logs_pagination.limit
                    }).then(res => {
                        if(res.errcode >= that.$consts.biz.OK && res.data ) {
                            that.add_logs = res.data
                            that.add_logs_pagination.page = page
                            that.add_logs_pagination.end = res.data.length < that.add_logs_pagination.limit
                            resolve()
                        }
                    })
                })

            },

            queryExchangeLogs(page = 1) {
                const that = this
                return new Promise((resolve,reject) => {
                    doQuery({
                        query_code: 'LNSC30',
                        page: page,
                        limit: that.exchange_logs_pagination.limit
                    }).then(res => {
                        if(res.errcode >= that.$consts.biz.OK && res.data ) {
                            that.exchange_logs = res.data
                            that.exchange_logs_pagination.page = page
                            that.exchange_logs_pagination.end = res.data.length < that.exchange_logs_pagination.limit
                            resolve()
                        }
                    })
                })
            },
        }
    }
</script>

<style scoped>
    /deep/ .ant-modal-header {
        padding: 6px 0px;
        border-bottom: none;
    }
</style>
