<template>
  <div class="huiwen-container huiwen-layout-flex-center">
    <div v-if="!(page_now == 1 && bo_end)">
      <div class="huiwen-left huiwen-padding-all-5 huiwen-border-box page-btn-l" v-if="page_now > 1" @click="previous_fn">上一页</div>
      <div class="huiwen-left huiwen-padding-all-5 huiwen-border-box page-num">{{page_now}}</div>
      <div class="huiwen-left huiwen-padding-all-5 huiwen-border-box page-btn-r" v-if="!bo_end" @click="next_fn">下一页</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cmy-page-old",
  props: {
    page_now: Number,
    bo_end: {
      type: Boolean,
      default: false
    }
  },
  methods:{
    previous_fn(){
      this.$emit('previous')
    },
    next_fn(){
      this.$emit('next')
    }
  }
}
</script>

<style scoped>

.page-btn-l {
  width: 60px;
  border-left: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  cursor: pointer;
  text-align: center;
}
.page-btn-l:hover {
  color: #ffffff;
  background-color: #1d88fa;
  border-color: #1d88fa;
}
.page-btn-r {
  width: 60px;
  border-right: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
  text-align: center;
}
.page-btn-r:hover {
  color: #ffffff;
  background-color: #1d88fa;
  border-color: #1d88fa;
}
.page-num {
  width: 50px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  text-align: center;
}
</style>