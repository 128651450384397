<template>
    <a-layout>
        <a-layout-header :style="{ position: 'fixed', zIndex: 9, width: '100%' }" class="huiwen-layout-flex-center" >
            <div style="width: 1200px">
                <div class="huiwen-left huiwen-layout-flex-center" @click="$router.push({name:'Index'})" style="height: 64px;cursor:pointer;">
                    <img :src="logo"  style="height: 50px;"/>
                </div>
                <a-menu
                        theme="dark"
                        class="huiwen-left"
                        mode="horizontal"
                        :selectedKeys="[$route.name]"
                        :style="{ 'line-height': '64px', 'margin-left': '50px' }"
                        @click="onMenuSelect"
                >
                    <a-menu-item key="StudentIndex">
                        首页
                    </a-menu-item>
                    <a-menu-item key="StudentTask">
                        任务
                    </a-menu-item>
                    <a-menu-item key="StudentLesson">
                        课程
                    </a-menu-item>
                    <a-menu-item key="StudentPublishQuestion">
                      <span class="beta-tag">beta</span>
                        AI咨询
                    </a-menu-item>
                    <a-sub-menu>
                        <span slot="title">AI训练 <a-icon type="caret-down" /></span>
                        <a-menu-item key="StudentBrushQuestion">
                            <i class="iconfont icon-bishitiku"></i>
                            <span style="margin-left: 16px">智能刷题训练</span>
                        </a-menu-item>
                        <a-menu-item key="StudentTextPractice" disabled>
                            <i class="iconfont icon-rengongzhinengdanao"></i>
                            <span style="margin-left: 16px">智能对话训练</span>
                        </a-menu-item>
                    </a-sub-menu>
                    <a-sub-menu>
                        <span slot="title">工具箱 <a-icon type="caret-down" /></span>
                        <a-menu-item key="StudentKnowledge">
                            <i class="iconfont icon-wendaku"></i>
                            <span style="margin-left: 16px">咨询问答库</span>
                        </a-menu-item>
                        <a-menu-item key="StudentTaxGuide">
                            <i class="iconfont icon-zhinanzhidao-xianxing"></i>
                            <span style="margin-left: 16px">办税指南库</span>
                        </a-menu-item>
                        <a-menu-item key="tax_operation_manual_new">
                          <i class="iconfont icon-yewushouce"></i>
                          <span style="margin-left: 16px">全国版电子税务局操作手册</span>
                        </a-menu-item>
                        <a-menu-item key="tax_operation_manual">
                            <i class="iconfont icon-yewushouce"></i>
                            <span style="margin-left: 16px">热点操作文档</span>
                        </a-menu-item>
                        <!--a-menu-item key="app_operation_manual">
                            <i class="iconfont icon-knowledge"></i>
                            <span style="margin-left: 16px">税务APP操作手册</span>
                        </a-menu-item-->
                        <a-menu-item key="ptax_koujiao_manual">
                          <i class="iconfont icon-jianjie"></i>
                          <span style="margin-left: 16px">个税扣缴端操作手册</span>
                        </a-menu-item>
                        <a-menu-item key="question_analyze">
                            <i class="iconfont icon-V" style="font-size:22px;"></i>
                            <span style="margin-left: 11px">热点问题深度解析</span>
                        </a-menu-item>
                        <a-menu-item key="tags_types_guide">
                          <a-icon type="tags"  style ='font-size:18px;'/><span style="margin-left: 5px">标签及问题类型</span>
                        </a-menu-item>
                    </a-sub-menu>
                  <a-menu-item key="simulation" @click="openSimulation1($event)">
                    <a href="javascript:void(0)" onclick="window.open('https://www.xueshuitong.com/moni/%E6%BC%94%E7%A4%BA%E6%A8%A1%E5%BC%8F.html', '_blank')">模拟学习</a>
                  </a-menu-item>
                    <!--
                    <a-menu-item key="StudentTools">
                        <a-dropdown>
                            <span>工具箱</span>
                            <a-menu slot="overlay"  @click="onToolMenuClick">
                                <a-menu-item key="tax_operation_manual">
                                    <i class="iconfont icon-yewushouce"></i>
                                    <span style="margin-left: 16px">电子税务局各项功能操作手册</span>
                                </a-menu-item>
                                <a-menu-item key="question_analyze">
                                    <i class="iconfont icon-V"></i>
                                    <span style="margin-left: 16px">热点问题深度解析</span>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </a-menu-item>
                    -->
                </a-menu>

                <div class="huiwen-right" style="height: 64px">
                    <div class="huiwen-left huiwen-layout-flex-tb-center" style="height: 64px;margin-left: 8px">
                        <a-dropdown :trigger="['click']">
                            <a-input-search style="width: 180px" placeholder="搜索学习过的任务/课程/考题" v-model="searchText" @search="onSearch"/>
                            <template v-if="searchLogs.length > 0">
                                <a-menu slot="overlay" @click="onSearchLogsMenuClick">
                                    <a-menu-item v-for="item in searchLogs" :key="item">{{item}}</a-menu-item>
                                </a-menu>
                            </template>
                        </a-dropdown>
                    </div>
                    <div class="huiwen-left huiwen-pointer" style="margin-left: 8px">
                        <a-dropdown v-if="myInfo && myInfo.headimgurl">
                            <a-badge :dot="myStaffInfo.msg_unread > 0">
                                <a-avatar shape="square" :src="myInfo.headimgurl" />
                            </a-badge>

                            <a-menu slot="overlay"  @click="onMenuClick">
                                <a-menu-item key="MyMessage">
                                    <i class="iconfont icon-xiaoxi"></i>
                                    <span style="margin-left: 16px">我的消息</span>
                                    <span style="margin-left: 16px;color: #f5222d;font-weight: bold">{{myStaffInfo.msg_unread == 0 ? '' : myStaffInfo.msg_unread}}</span>
                                </a-menu-item>
                                <a-menu-item key="MyFavorites">
                                    <i class="iconfont icon-shoucang4"></i>
                                    <span style="margin-left: 16px">我的收藏</span>
                                </a-menu-item>
                                <a-menu-item key="MyExamination">
                                    <i class="iconfont icon-zaixiankaoshi"></i>
                                    <span style="margin-left: 16px">我的考试</span>
                                </a-menu-item>
                                <!--<a-menu-item key="DistributeKnowledge">
                                    <i class="iconfont icon-knowledge"></i>
                                    <span style="margin-left: 16px">贡献知识</span>
                                </a-menu-item>-->
                                <a-menu-item key="MyWrongQuestion">
                                    <i class="iconfont icon-cuotiku"></i>
                                    <span style="margin-left: 16px">我的错题</span>
                                </a-menu-item>
                                <a-menu-item key="MyPractice">
                                    <i class="iconfont icon-a-kaoshilianxi"></i>
                                    <span style="margin-left: 16px">我的训练</span>
                                </a-menu-item>
                                <a-menu-item class="huiwen-border-bottom-gray" key="MyIntegration">
                                    <i class="iconfont icon-jifen" style="font-size:15px;"></i>
                                    <span style="margin-left: 16px">我的学豆</span>
                                </a-menu-item>
                                <a-menu-item key="Quit">
                                    <i class="iconfont icon-tuichu"></i>
                                    <span style="margin-left: 16px">退出登录</span>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                        <a-avatar v-else style="backgroundColor:#87d068" shape="square" icon="user" />
                    </div>
                    <div class="huiwen-left" style="color: rgba(255, 255, 255, 0.65);margin-left: 8px;font-size: 14px">{{ myInfo.name }}</div>
                    <!--div class="huiwen-left" style="color: #797979;margin-left: 8px;font-size: 14px">{{ myInfo.deptName }}</div-->
                </div>
            </div>
        </a-layout-header>
        <a-layout-content>
            <div class="huiwen-container huiwen-left" :style="{'height':'64px'}"></div>
            <div class="huiwen-container huiwen-left" id="layout-main" :style="{ 'background': '#fff', 'min-height': (winSize[1] - 136) + 'px' }">
                <series-task-drawer></series-task-drawer>
                <training-task-drawer></training-task-drawer>
                <router-view :key="key"></router-view>
            </div>
        </a-layout-content>
        <a-layout-footer :style="{ 'text-align': 'center','height':'70px','font-weight':'bold' }">
            ©2021 学税通 版权所有 <a rel="nofollow" href="https://beian.miit.gov.cn">苏ICP备14039661号-7</a>
        </a-layout-footer>

        <message-center :visible="messageCenter" @close="messageCenter = false"></message-center>
        <my-favorite-lesson :visible="myFavoriteLesson" @close="myFavoriteLesson = false" ></my-favorite-lesson>
        <my-examination :visible="myExamination" @close="myExamination = false"></my-examination>
        <my-wrong-question :visible="myWrongQuestion" @close="myWrongQuestion = false"></my-wrong-question>
        <my-integration :visible="MyIntegration" @close="MyIntegration = false"></my-integration>
        <my-practice :visible="MyPractice" @close="MyPractice = false"></my-practice>

    </a-layout>
</template>

<script>
    import logo from '@/assets/logo_title.png'
    import {mapGetters,mapActions,mapMutations} from 'vuex'
    import TrainingTaskDrawer from '@/components/training-task-drawer/training-task-drawer.vue'
    import SeriesTaskDrawer from '@/components/series-task-drawer/series-task-drawer.vue'
    import MessageCenter from '@/components/message-center/message-center.vue'
    import MyFavoriteLesson from '@/components/my-favorite-lesson/my-favorite-lesson.vue'
    import MyExamination from '@/components/my-examination/my-examination.vue'
    import MyWrongQuestion from '@/components/my-wrong-question/my-wrong-question.vue'
    import MyIntegration from '@/components/my-integration/my-integration.vue'
    import MyPractice from '@/components/my-practice/my-practice.vue'

    export default {
        name: "StudentLayout",
        data() {
            return {
                logo: logo,
                searchLogs: [],
                searchText: undefined,

                messageCenter: false,
                myFavoriteLesson: false,
                myExamination: false,
                myWrongQuestion: false,
                MyIntegration: false,
                MyPractice: false
            }
        },
        components: {
            TrainingTaskDrawer,
            SeriesTaskDrawer,
            MessageCenter,
            MyFavoriteLesson,
            MyExamination,
            MyWrongQuestion,
            MyIntegration,
            MyPractice
        },
        computed: {
            ...mapGetters([
                'winSize',
                'myInfo',
                'myStaffInfo',
                'mobilephone'
            ]),
            key(){
                return this.$route.name ? this.$route.name+'-'+new Date() : this.$route +'-'+ new Date()
            }
        },
        watch: {
            '$route.name': function(newVal,oldVal) {
                if(oldVal == 'StudentSearchRes' && newVal != oldVal) {
                    this.searchText = undefined
                }
            }
        },
        mounted() {
            const that = this
            that.searchLogs = localStorage.getItem(that.$consts.storageKey.XST_SEARCH_LOG_.concat(that.mobilephone)) ? JSON.parse(localStorage.getItem(that.$consts.storageKey.XST_SEARCH_LOG_.concat(that.mobilephone))) : []
        },
        methods: {
            ...mapActions(['Logout']),
            ...mapMutations(['SET_SEARCH_TEXT']),


          openSimulation(event) {
            if (event && event.preventDefault) {
              event.preventDefault();
            }
            window.open('https://www.xueshuitong.com/moni/%E6%BC%94%E7%A4%BA%E6%A8%A1%E5%BC%8F.html', '_blank');
          },

            onMenuSelect({ item, key, selectedKeys }) {
                const that = this

                if (key=='tax_operation_manual' || key=='tax_operation_manual_new' || key == 'question_analyze' || key == 'app_operation_manual' || key == 'tags_types_guide' || key == 'ptax_koujiao_manual') {
                  console.log(key)
                    that.$router.push('/student/tools?type=' + key)
                } else {
                    that.$router.push({ name: key })
                }
            },

            onMenuClick({ key }) {
                const that = this
                if(key == 'Quit') {
                    that.$confirm({
                        centered:true,
                        title: '您即将退出登录，是否继续？',
                        onOk() {
                            that.Logout().then(() => {
                                that.$router.push({name: 'StudentLogin'})
                            })
                        }
                    })
                }
                if(key == 'MyMessage') {
                    that.messageCenter = true
                }
                if(key == 'MyFavorites') {
                    that.myFavoriteLesson = true
                }
                if(key == 'MyExamination') {
                    that.myExamination = true
                }
                if(key == 'MyWrongQuestion') {
                    that.myWrongQuestion = true
                }
                if(key == 'MyPractice') {
                    that.MyPractice = true
                }
                if(key == 'MyIntegration') {
                    that.MyIntegration = true
                }
                if(key == 'DistributeKnowledge') {
                    that.$router.push({name: 'StudentDistributeKnowledge'})
                }
            },
            onSearchLogsMenuClick({ key }) {
                const that = this
                if(!key) {
                    that.$router.push({name: 'StudentIndex'})
                    return
                }
                that.searchText = key
                var searchLogs = [...that.searchLogs]
                var arr = []
                var index = null
                for(var i = 0; i < searchLogs.length; i++) {
                    if(key == searchLogs[i]) {
                        index = i
                        break
                    }
                }
                if(index != null) {
                    searchLogs.splice(index, 1);
                    arr = [key,...searchLogs]
                    that.searchLogs = arr
                    localStorage.setItem(that.$consts.storageKey.XST_SEARCH_LOG_.concat(that.mobilephone),JSON.stringify(arr))
                    that.doSearch(key)
                }
            },
            onSearch(value, event) {
                const that = this
                if(!value.trim()) {
                    that.$router.push({name: 'StudentIndex'})
                    return
                }
                var searchLogs = [...that.searchLogs]
                var arr = []
                var index = null
                for(var i = 0; i < searchLogs.length; i++) {
                    if(value.trim() == searchLogs[i]) {
                        index = i
                        break
                    }
                }
                if(index != null) {
                    searchLogs.splice(index, 1);
                    arr = [value.trim(),...searchLogs]
                }else {
                    if(searchLogs.length < 10) {
                        arr = [value.trim(),...searchLogs]
                    }else {
                        arr = [value.trim(),...searchLogs]
                        arr.shift();
                    }
                }
                that.searchLogs = arr
                localStorage.setItem(that.$consts.storageKey.XST_SEARCH_LOG_.concat(that.mobilephone),JSON.stringify(arr))
                that.doSearch(value)
            },
            doSearch(searchText) {
                const that = this
                if(that.$route.name != 'StudentSearchRes') {
                    that.$router.push({name: 'StudentSearchRes'})
                }
                that.SET_SEARCH_TEXT(searchText)
            },

        }
    }
</script>

<style scoped>
    /deep/ .ant-input {
        border-radius: 2px;

    }
    /deep/ .ant-input-affix-wrapper .ant-input:focus {

        box-shadow: none;
    }
    /deep/ .ant-input-affix-wrapper .ant-input:hover {

    }

    /deep/ .ant-input::placeholder {
        font-size: 13px;
    }

    .beta-tag{
      position: absolute;
      top: 8px;
      right: 0px;
      font-size: 10px;
      background: #e93d52;
      color: white;
      height: 16px;
      line-height: 16px;
      border-radius: 4px;
      padding: 1px 6px;
    }
</style>
